@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"), url(/fonts/06113bf748ae7dc33e05189a90815625.ttf) format("truetype");
  font-display: block;
}

@font-face {
  font-family: "Helvetica-Bold";
  src: local("Helvetica-Bold"),
    url(/fonts/ed57aca7292971d8548c4554f08c0255.ttf) format("truetype");
  font-display: block;
}

*:focus {
  outline: none;
}

.loader{
  position:fixed; top:0;
  left:0; right:0; bottom:0;     
  background:rgba(255,255,255,.5);
  /*padding-top:150px;*/
  padding-top:20px;
  z-index:99999;
 }
 
 .loader div {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #547c22; /* Blue */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    vertical-align: middle;
    margin: auto;
    animation: spin 2s linear infinite;
 }
 
 @keyframes spin {
     0% { transform: rotate(0deg); }
     100% { transform: rotate(360deg); }
 }

img {
  width: 100%;
  height: 100%;
}

.recaptcha-wrapper {
  width: 300px;
  height: 74px;
  margin: 20px auto 20px auto;
}

.ais_show-in-web {
  display: block;
  padding-left: 0px;
}
.ais_logo-wrapper {
  text-align: center;
  padding-top: 100px;
}
.ais_logo-wrapper img {
  width: auto; /* IE fix */
}

.ais_container {
  background-image: url(/images/img-web_bg.svg);
  position: relative;
  background-repeat: no-repeat;
  background-position: 0 10px;
  height: 100vh;
}

.ais_container-wrapper {
  margin: auto;
}

.ais_maintenance_text {
  font-family: Helvetica-Bold;
  padding-top: 5px;
  color: #547c22;
  font-weight: 700;
  font-size: 20px;
}

.faq-iframe {
  width: 100%;
  height: 100vh;
}

#faq_header {
  width: auto;
  font-family: Helvetica-Bold;
  font-size: 26px;
  color: #3d3d3d;
  text-align: center;
  line-height: 32px;
}

/* .landing-page-wrapper {
  background-image: url(../images/img-web_bg.svg);
  background-position: right;
  background-repeat: no-repeat;
  background-size: 180% 180%;
} */
.landing-mid-section {
  margin: auto;
  width: 70%;
  text-align: center;
}

.ais-sr-logo {
  margin-top: 120px;
  width: 130px;
  text-align: center;
}

#ais_header {
  width: auto;
  font-family: Helvetica-Bold;
  font-size: 26px;
  color: #3d3d3d;
  padding-top: 180px;
  line-height: 32px;
}
#ais_header span{
  font-family: Helvetica;
}
#ais_header br {
  display: none;
}
#ais_sub-header {
  width: 80%;
  text-align: left;
  line-height: 20px;
  margin: 20px auto 20px auto;
  font-family: Helvetica;
  font-size: 14px;
  color: #3d3d3d;
}

.desktop-display {
  display: block;
}

.mobile-display {
  display: none;
}

.ais_language-div {
  text-align: right;
  margin: 30px 25px 0 0;
  color: #3d3d3d;
  /* display:none; */
}
.ais_language-div a {
  color: #3d3d3d;
}
.ais_language-div a:hover,.ais_language-div a:focus {
  color: #547c22;
  font-weight: 700;
  text-decoration: none;
}

.ais_inputlabel-mobile {
  font-size: 15px;
  font-family: Helvetica;
  /* font-weight: bold; */
  line-height: 16px;
  text-align: center;
  color: #3d3d3d;
}
.error {
  text-align: left;
  font-size: 13px;
  color: red;
  width: 350px;
  font-family:Helvetica;
  margin: 10px 0px 0px 0px;
}

.hide {
  display: none;
}
.ais_input-wrapper {
  width: 80%;
  margin: auto;
  height: 50px;
  text-align: center;
  margin-bottom: 5px;
  position: relative;
  border: solid 1.5px #E7E9EA;
  border-radius: 22px;
  background-color: #ffffff;
}
.ais_input-mobile-number {
  width: 85%;
  font-family: Helvetica;
  font-size: 15px;
  color: #3d3d3d;
  height: 46px;
  border: none;
  outline: 0ex;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::-ms-clear {
  width : 0;
  height: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.ais_disclaimer-wrapper {
  font-family: Helvetica;
  color: #3d3d3d;
  padding-top: 2px;
  line-height: 1.5;
}
/* #ais_disclaimer {
  font-size: 13px;
  line-height: 15px;
  width: 80%;
  margin: 30px auto 80px auto;
  text-align: justify;
} */
#ais_disclaimer {
  font-size: 13px;
  line-height: 15px;
  width: 80%;
  margin: 30px auto 20px auto;
  text-align: justify;
}
#ais_payment_disclaimer {
  font-size: 13px;
  line-height: 15px;
  width: 80%;
  margin: 0 auto 80px auto;
  text-align: justify;
}
.ais_submit-button-wrapper {
  text-align: center;
}
.ais_submit-button-wrapper button {
  font-family: Helvetica;
  font-size: 15px;
  font-weight: bold;
  width: 350px;
  height: 44px;
  border-radius: 25px;
  text-align: center;
  border: none;
  outline: none;
  background-color: #bfd22b;
  color: #fff;
}
.ais_submit-button-wrapper button:disabled {
  background-color: #E7E9EA;
  color: #C0C2C4;
}
/* .ais_submit-button-wrapper button:hover {
  background-color: #bfd22b;
  color: #fff;
} */
.desktopErrorMsg {
  color: #ed1a3d;
  font-size: 13px;
  font-family: Helvetica;
  margin: 20px;
}
.error-msg-hide {
  display: none;
}
.errorBorder {
  border: none;
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(237, 26, 61, 0.25);
}
.ais_input-mobile-number.errorBorder::placeholder {
  color: #ed1a3d;
}
.ais_footer {
  position: absolute;
  bottom: 0;
  left: 0;
  font-family: Helvetica;
  font-size: 12px;
  color: #3d3d3d;
  padding-bottom: 10px;
  padding-top: 20px;
  width: 100%;
  text-align: center;
}
.ais_footer a {
  color:#3d3d3d;
}
.ais_greenish {
  font-family: Helvetica;
  font-size: 13px;
  color:#547c22;
  font-weight: 700;
  cursor: pointer;
}

.faq {
  display: inline-block;
}

.ais_web-display {
  display: inline;
}

#ais_copyright {
  margin-right: 30px;
}

.modal-content {
  width: 70%;
  margin: auto;
}

.modal-header {
  padding: 0px 10px 0px 0px;
  border-bottom: 0px;
}

.close {
  color: #BFD22B;
}

.close:hover {
  color: #BFD22B;
}

.close:focus {
  outline: none;
}

.ais_modal-content-label {
  font-size: 17px;
  font-family: Helvetica-Bold;
  text-align: center;
  color: #3d3d3d;
  line-height: 20px;
}

.ais_modal-content {
  font-size: 14px;
  font-family: Helvetica;
  color: #3d3d3d;
  margin-top: 20px;
}

@media (max-height: 700px) {
  .ais_container {
    height: auto;
    background-size: 100% 900px;
  }
}

@media (max-width: 1024px) {
  .ais_container {
    height: auto;
    background-size: 100%;
  }
}

@media (max-width: 768px) {
  .ais_container {
    background: url(/images/ais-sr-mobile-bg.svg);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100% 250px;
    height: auto;
  }
  .landing-mid-section {
    width: auto;
    margin: auto;
    text-align: left;
  }
  .landing-page-wrapper {
    display: none;
  }

  .desktop-display {
    display: none;
  }

  .mobile-display {
    margin-top: 110px;
    display: block;
  }

  .ais-sr-logo {
    margin-top: 40px;
    width: 130px;
  }

  #ais_header {
    font-size: 18px;
    padding-top: 80px;
  }
  #ais_header span{
    display: block;
    font-family: Helvetica;
  }

  #ais_sub-header {
    width: auto;
    font-size: 13px;
    line-height: 17px;
  }
  .ais_language-div {
    margin: 0px 10px 0 0;
  }
  .ais_input-wrapper {
    width: 90%;
    height: 48px;
  }
  .ais_submit-button-wrapper button {
    width: 85%;
    font-weight: normal;
  }
  .ais_input-mobile-number {
    width: 85%;
    height: 44px;
  }

  #ais_disclaimer {
    font-size: 11px;
    width: auto;
  }
  .ais_footer {
    text-align: center;
    font-size: 11px;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  #ais_copyright {
    margin: auto;
  }
  .faq {
    font-size: 12px;
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .ais_inputlabel-mobile {
    font-size: 13px;
  }
  .ais_modal-content-label {
    font-size: 14px;
    line-height: 17px;
  }
  .ais_modal-content {
    font-size: 11px;
  }

}
@media only screen and (max-device-width: 414px) {
  .ais_show-in-web {
    display: none;
    padding-left: 0px;
  }
  .ais_web-display {
    display: block;
  }
  .ais_language-div {
    margin-right: 12px;
  }
}
@media only screen and (device-width: 320px) {
  .ais_footer {
    /* position: static;
    bottom: 0; */
    font-family: Helvetica;
    font-size: 12px;
    color: rgb(61, 61, 61);
    padding-bottom: 10px;
    padding-top: 10px;
  }
}

 .headerText{
        font-family:Helvetica;
        font-size: 28px;
        font-weight: normal;
        margin:19px 771px 21px 95px;
        float: left;
    }

  .headerTermsText{
      font-family: Helvetica;
      font-size: 22px;
      font-weight: 100;
      font-style: normal;
      font-stretch: normal;
      margin:8px 30px;
      float: left;
      height: 50px;
    }


    .headerTermsTime{
        display:none;
        margin: 0px;
    }

    .headerTime{
        font-family: Helvetica;
        font-size: 14px;
        font-weight: normal;
        margin:29px 80px 21px 0px;
        float: right;
        display:block;
    }

  .acc-header{
        font-family:  Helvetica-Bold;
        font-size: 18px;
        font-weight: 900;
        text-align: center;
        color: #1d3444;
        width:100%;
    }  
    .headerTopSpace{
        margin-top: 4px;
    }

    .headings{
        color:#1d3444;
        font-weight: 900;
    }

    .headerTitle{
        color:#1d3444;
        font-weight: 900;
        margin: 35px;
        margin-bottom: 0px;
    }



    .mobileSwop-logo{
        width: 9%;
        height: 8%;
    }

    #accordion .panel-body{
        padding:0px;
        text-align: center;
    }
      .topSpace{
        margin-top: 20px;
    }
    #accordion .panel{
        border: none;
        border-radius: 20px;
        box-shadow: none;
        margin-bottom: 15px;
    }
    #accordion .panel-heading{
        padding: 0;
        border: none;
        border-radius: 20px;
    }
     
    #accordion .panel-title {
    text-align: left;
    font-family: Helvetica-bold;
    font-weight: 900;
    }

    #accordion .panel-title a{
        display: block;
        padding: 12px 15px;
        background: #fff;
        font-family: Helvetica;
        font-size: 17px;
        font-weight: 400;
        color: #6ea32d;
        /*border: 1px solid #ececec;*/
        box-shadow: 0 0 10px rgba(0,0,0,.05);
        position: relative;
        transition: all 0.5s ease 0s;
        box-shadow: 0 1px 2px rgba(43,59,93,0.30);
        text-decoration: none;
    }
    .headings{
        color:#1d3444;
    }
    #accordion .panel-title a.collapsed{
        box-shadow: none;
        color: #1d3444;
        box-shadow: 0 1px 2px rgba(43,59,93,0.30);
    }
    #accordion .panel-title a:before,
    #accordion .panel-title a.collapsed:before{
        content: "\f067";
        font-family: "Font Awesome 5 Free";
        width: 25px;
        height: 25px;
        line-height: 28px;
        font-size: 15px;
        font-weight: 900;
        color: #6ea32d;
        text-align: center;
        position: absolute;
        top: 8px;
        right: 15px;
        transform: rotate(135deg);
        transition: all 0.3s ease 0s;
    }
    #accordion .panel-title a.collapsed:before{
        color: #676767;
        transform: rotate(0);
    }
    #accordion .panel-title a:after{
        content: "";
        width: 1px;
        height: 100%;
        background: #ececec;
        position: absolute;
        top: 0;
        right: 55px;
    }
    #accordion .panel-body{
        padding: 0px 15px;
        border: none;
        font-family: Helvetica;
        font-weight: normal;
        font-size: 15px;
        color: #1d3444;
        text-align: justify;
    }


.panel-group ol {
    counter-reset: item;
       list-style-type: decimal;
       list-style-position: inside;
       text-indent: -25px;
       padding-left:25px;
}
.panel-group li {
    display: block
}
 .panel-group li:before {
    content: counters(item, ".") " ";
    counter-increment: item
}

.eligibility {
 list-style-type: none;
}

.eligibility li {
   list-style-type: none;
}

table {
        border-spacing: 0!important;
        border-collapse: collapse!important;
        padding: 0!important;
        margin: 0!important;
    }

    .terms-conditions-table th {
        background-color: #d3d3d3;
    }
    .terms-conditions-table td, .terms-conditions-table th {
        padding: 10px;
        text-align: left;
        padding-left: 50px;
    }
    .terms-conditions-table table, .terms-conditions-table td, .terms-conditions-table th {
        border: 1px solid #000;
    }

    .terms-table {
        border-spacing: 0!important;
        border-collapse: collapse!important;
        padding: 0!important;
        margin: auto!important;
        width: 50% !important;
    }

    .terms-table th {
        background-color: #d3d3d3;
    }
    .terms-table td, .terms-table th {
        padding: 10px;
        text-align: center;
    }
    .terms-table table, .terms-table td, .terms-table th {
        border: 1px solid #000;
    }
